import { useState, useRef } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { ImageEditor } from '../../../Modal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'jimp';

const Alert = withReactContent(Swal);

const EditGroup = () => {
  const [modalOpen, updateModal] = useState(false);
  const [updatedFields, changeUpdatedFields] = useState({});
  const [tempAvatar, updateTempAvatar] = useState('');

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    if (e.target.name === 'avatar') {
      //Checks if the file uploaded is an image
      if (!!e.target.files[0].type.match('image.*')) {
        Jimp.read(URL.createObjectURL(e.target.files[0]), async (err, img) => {
          if (err) {
            console.log(err);
            toast.error('Error processing image!');
            return;
          }
          const mime = await img.getBase64Async(Jimp.MIME_JPEG);
          updateTempAvatar(mime);
        });
        requestCloseModal();
      } else {
        notAnImage();
        e.target.value = '';
      }
      return; //Don't want to set updated fields here in case the user cancels the crop
    }
    changeUpdatedFields({ ...updatedFields, [e.target.name]: e.target.value });
  };
  const saveCroppedAvatar = (mime) => {
    updateTempAvatar('');
    updateAvatar(mime);
    requestCloseModal();
    saveAvatarToAWS(mime);
  };

  const requestCloseModal = () => {
    updateModal(!modalOpen);
    updateTempAvatar('');
  };

  const saveAvatarToAWS = (updatedAvatar) => {
    //Using Fetch here to send along the base64 encoded image
    fetch(`/api/avatar/groupAvatar/${groupInfo._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ image: updatedAvatar }),
    }).then(() =>
      toast.success('Avatar Saved', {
        duration: 4000,
      })
    );
  };
  const notAnImage = () => {
    Alert.fire({
      title: 'Only Upload Images',
      text: 'File is not an image. Please only upload images',
      showConfirmButton: false,
      showCancelButton: true,
    });
  };
  return (
    <>
      <div>Admin View </div>
      <Modal
        onRequestClose={requestCloseModal}
        isOpen={modalOpen}
        contentLabel='modalWindow'
        className={'modalWindow'}
        overlayClassName='modalOverlay'
        ariaHideApp={false}
      >
        <ImageEditor
          tempAvatar={tempAvatar}
          saveCroppedAvatar={saveCroppedAvatar}
          openCloseModal={requestCloseModal}
          fileInputRef={fileInputRef}
        />
      </Modal>
    </>
  );
};

export default EditGroup;
