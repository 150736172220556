import { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { CurrentUserContext } from '../../../../contexts/CurrentUser';
import * as Routes from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import Session from '../../../../contexts/Firebase/Session';
import { axiosHandler, httpErrorHandler } from '../../../../utils/axiosHandler';
import GenericGroupAvatar from '../../../../constants/logoImages/avatar/groupPlaceholder.png';
import Modal from 'react-modal';
import { ImageEditor } from '../../../Modal';
import { countDecimals } from '../../../../utils/genericTools';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alert = withReactContent(Swal);

const CreateGroup = () => {
  const [groupName, updateGroupName] = useState('');
  const [groupDescription, updateGroupDescription] = useState('');
  const [tempAvatar, updateTempAvatar] = useState(GenericGroupAvatar);
  const [modalOpen, updateModalOpen] = useState(false);
  const [rosterSelect, updateRosterSelect] = useState({
    quarterbacks: 1,
    runningbacks: 2,
    widereceivers: 2,
    tightends: 1,
    runningbackWidereceiver: 0,
    flex: 1,
    superFlex: 0,
    kickers: 0,
  });
  const [hidePlayersToggle, updateHidePlayersToggle] = useState(false);
  const [namingErrors, updateNamingErrors] = useState([]);
  const [rosterErrors, updateRosterErrors] = useState([]);
  const [avatarToUpload, updateAvatarToUpload] = useState(null);
  const [scoringMap, updateScoringMap] = useState({});
  const [enteredScore, updateEnteredScore] = useState({});

  const { currentUser, pullUserData } = useContext(CurrentUserContext);
  const history = useHistory();

  const axiosCancel = axios.CancelToken.source();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (currentUser.email) {
      getScoringSystem();
    }
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel('Unmounted');
      }
    };
  }, [currentUser]);

  useEffect(() => {
    const errors = [];
    if (groupName.trim().length > 20) {
      errors.push('Group name must be shorter than 20 characters');
    }
    if (groupDescription.trim().length > 160) {
      errors.push('Group description must be shorter than 160 characters');
    }
    updateNamingErrors(errors);
  }, [groupName, groupDescription]);

  useEffect(() => {
    const errors = [];
    if (Object.values(rosterSelect).reduce((total, x) => total + x) > 15) {
      errors.push('Must have 15 or less roster spots');
    }
    if (rosterSelect.quarterbacks + rosterSelect.superFlex > 3) {
      errors.push('No more than 3 quarterbacks allowed (including Super Flex)');
    }
    if (
      rosterSelect.runningbacks +
        rosterSelect.runningbackWidereceiver +
        rosterSelect.flex +
        rosterSelect.superFlex >
      7
    ) {
      errors.push(
        'No more than 7 running backs allowed (including RB/WR, Flex, and Super Flex)'
      );
    }
    if (
      rosterSelect.widereceivers +
        rosterSelect.runningbackWidereceiver +
        rosterSelect.flex +
        rosterSelect.superFlex >
      7
    ) {
      errors.push(
        'No more than 7 wide receivers allowed (including RB/WR, Flex, and Super Flex)'
      );
    }
    if (
      rosterSelect.tightends + rosterSelect.flex + rosterSelect.superFlex >
      3
    ) {
      errors.push(
        'No more than 3 tight ends allowed (including Flex and Super Flex)'
      );
    }
    if (rosterSelect.kickers > 2) {
      errors.push('No more than 2 kickers allowed');
    }
    updateRosterErrors(errors);
  }, [
    rosterSelect.quarterbacks,
    rosterSelect.runningbacks,
    rosterSelect.widereceivers,
    rosterSelect.runningbackWidereceiver,
    rosterSelect.flex,
    rosterSelect.superFlex,
    rosterSelect.kickers,
  ]);

  const groupValidationErrors = async () => {
    const errors = [];
    if (namingErrors.length > 0) {
      errors.push(...namingErrors);
    }

    //Only check naming errors for over length, verify smaller here
    if (groupName.length < 6) {
      errors.push('Group name must be at least 6 characters');
    }
    if (groupDescription.length < 6) {
      errors.push('Group description must be at least 6 characters');
    }

    if (rosterErrors.length > 0) {
      errors.push(...rosterErrors);
    }

    for (const bucket of Object.keys(enteredScore)) {
      for (const [innerKey, innerValue] of Object.entries(
        enteredScore[bucket]
      )) {
        if (innerValue === '-' || innerValue === '-.' || innerValue === '.') {
          //Do nothing, will be replaced by 0
        } else if (isNaN(innerValue)) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} is not a number`
          );
        } else if (innerValue < -100) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} is too low, max -100`
          );
        } else if (innerValue > 100) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} is too high, max 100`
          );
        }
        if (countDecimals(innerValue) > 2) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} cannot go past 2 places past the decimal`
          );
        }
      }
    }
    return errors;
  };

  const requestCloseModal = () => {
    updateModalOpen(false);
  };

  const fireErrorModal = async (validationErrors) => {
    Alert.fire({
      title: 'Errors in group',
      html: validationErrors.map(
        (error) => `<br/><br/><small>${error}</small>`
      ),
      showConfirmButton: true,
      confirmButtonText: 'Close',
      showCancelButton: false,
    });
  };

  const handleSubmit = async () => {
    const validationErrors = await groupValidationErrors();
    if (validationErrors.length > 0) {
      fireErrorModal(validationErrors);
      return;
    }
    const sanitizedScore = {};
    for (const [key] of Object.entries(enteredScore)) {
      sanitizedScore[key] = {};
      for (const [innerKey, innerValue] of Object.entries(enteredScore[key])) {
        if (innerValue === '-' || innerValue === '-.' || innerValue === '.') {
          sanitizedScore[key][innerKey] = 0;
        } else {
          sanitizedScore[key][innerKey] = innerValue;
        }
      }
    }
    try {
      await axiosHandler.post('/api/group/create', {
        userId: currentUser.userId,
        groupScore: sanitizedScore,
        groupName: groupName.trim(),
        groupDescription: groupDescription.trim(),
        groupRoster: rosterSelect,
        hidePlayersUntilLocked: hidePlayersToggle,
      });
    } catch (err) {
      console.log({ err });
      if (err.response.status === 400) {
        Alert.fire({
          title: 'Invalid group',
          icon: 'error',
          html: err.response.data.map((error) => `<br/><br/>${error}`),
        });
      } else {
        httpErrorHandler(err);
      }
    }
  };

  const userCancelCrop = () => {
    updateTempAvatar(GenericGroupAvatar);
    updateAvatarToUpload(null);
  };

  const handleChange = (e) => {
    if (e.target.name === 'groupDescription') {
      updateGroupDescription(e.target.value);
    } else if (e.target.name === 'groupName') {
      updateGroupName(e.target.value);
    } else if (e.target.name === 'avatar') {
      if (!!e.target.files[0].type.match('image.*')) {
        const file = e.target.files[0];
        fileInputRef.current.value = '';
        if (file.type === 'image/webp') {
          notSupportedImage();
          e.target.value = '';
          return;
        }
        Jimp.read(URL.createObjectURL(file), async (err, img) => {
          if (err) {
            console.log(err);
            toast.error('Error processing image!');
            return;
          }
          const mime = await img.getBase64Async(Jimp.MIME_JPEG);
          updateAvatarToUpload(mime);
        });
        updateModalOpen(true);
      } else {
        notAnImage();
        e.target.value = '';
      }
    }
  };

  const updateGroupsScore = (e) => {
    let { name, value } = e.target;
    if (value === '-' || value === '-.' || value === '.') {
      //Do Nothing
    } else if (isNaN(+value)) {
      return;
    } else if (value > 100) {
      return;
    } else if (value < -100) {
      return;
    } else if (countDecimals(value) > 2) {
      return;
    }
    const [bucket, bucketKey] = name.split('-');
    const updatedScore = { ...enteredScore };
    updatedScore[bucket][bucketKey] = value;
    updateEnteredScore(updatedScore);
  };

  const getScoringSystem = async () => {
    const newGroupScore = {};
    try {
      const { data } = await axiosHandler.get(
        '/api/group/scoring',
        axiosCancel.token
      );
      updateScoringMap(data);
      for (const bucket of data.buckets) {
        newGroupScore[bucket] = {};
        for (const key of data[bucket]) {
          newGroupScore[bucket][key] = data.defaultScores[bucket][key];
        }
      }
      updateEnteredScore(newGroupScore);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const saveCroppedAvatar = (mime) => {
    updateAvatarToUpload(mime);
    updateModalOpen(false);
  };

  const notSupportedImage = () => {
    Alert.fire({
      title: 'Webp not supported',
      text: 'Webp files are not currently supported by editor, sorry for the inconvenience',
      showCancelButton: true,
      showConfirmButton: false,
    });
  };

  const notAnImage = () => {
    Alert.fire({
      title: `Only images allowed`,
      text: `File is not an image. Please only upload images`,
      showConfirmButton: false,
      showCancelButton: true,
    });
  };

  const updateRoster = (e) => {
    const tempRosterSelect = { ...rosterSelect };
    tempRosterSelect[e.target.id] = +e.target.value;
    updateRosterSelect(tempRosterSelect);
  };

  return (
    <div className='container'>
      <div className='mt-5 justify-content-center row'>
        <div className='col-xs-12 col-lg-8 border rounded shadow'>
          <div className='row'>
            <h3 className='col-12 text-center mt-3'>Create Group</h3>
          </div>

          {namingErrors.length > 0 &&
            namingErrors.map((error, i) => (
              <div className='row' key={`namingError-${i}`}>
                <div className='col-12 text-center'>
                  <small className='text-danger'>{error}</small>
                </div>
              </div>
            ))}

          <div className='row justify-content-center'>
            <div className='col-md-12 col-lg-6 mt-1 text-center'>
              <div className='row'>
                <div className='col-12'>
                  <img
                    className='rounded mt-1 groupAvatar'
                    name='avatar'
                    src={avatarToUpload ? avatarToUpload : tempAvatar}
                  />
                </div>
              </div>
              <div className='row mt-2 mb-3'>
                <div className='col-12'>
                  <label className='btn btn-sm btn-primary'>
                    <input
                      type='file'
                      hidden={true}
                      ref={fileInputRef}
                      name='avatar'
                      onChange={handleChange}
                    />
                    Upload Avatar
                  </label>
                </div>
              </div>
            </div>

            <div className='col-md-12 col-lg-6 mt-2'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12 fw-semibold'>Group Name</div>
                  </div>
                  <div className='row'>
                    <small className='col-11 ms-2'>
                      Must be at least 6 characters, less than 20
                    </small>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <input
                        className='form-control'
                        type='text'
                        name='groupName'
                        placeholder='Dragons of Doom'
                        value={groupName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-3 mt-2'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12 fw-semibold'>Group Description</div>
                  </div>
                  <div className='row'>
                    <small className='col-11 ms-2'>
                      Must be at least 6 characters, less than 160
                    </small>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <textarea
                        className='form-control'
                        type='text'
                        name='groupDescription'
                        placeholder='Burninating the Competition'
                        value={groupDescription}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 justify-content-center row'>
        <div className='col-xs-12 col-lg-8 border rounded shadow'>
          <div className='row mt-3'>
            <h3 className='col-12 text-center'>Roster</h3>
          </div>

          {rosterErrors.length > 0 &&
            rosterErrors.map((error, i) => (
              <div key={`rosterError-${i}`} className='row'>
                <div className='col-12 text-center mb-1'>
                  <small className='text-danger'>{error}</small>
                </div>
              </div>
            ))}

          <div className='row mb-2 justify-content-center'>
            <div className='col-11 pt-1 pb-2 border rounded'>
              <div className='row justify-content-center'>
                <div className='col-12 col-lg-6 ps-4'>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='hidePlayersUntilLocked'
                      value={hidePlayersToggle}
                      onClick={() =>
                        updateHidePlayersToggle(!hidePlayersToggle)
                      }
                    />
                    <label
                      className='form-check-label fw-semibold'
                      htmlFor='hidePlayersUntilLocked'
                    >
                      Hide rosters until week is locked
                    </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-center text-lg-start'>
                  <small>
                    Weeks lock every Tuesday morning. This will show rosters on
                    the leaderboards from the previous week rather than current
                    week.
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-12 col-lg-4'>
              <ul className='list-group list-group-flush text-center text-lg-start'>
                <li className='list-group-item'>
                  <small>15 Players Max</small>
                </li>
                <li className='list-group-item'>
                  <small>3 Quarterbacks Max</small>
                </li>
                <li className='list-group-item'>
                  <small>7 Running Backs Max</small>
                </li>
                <li className='list-group-item'>
                  <small>7 Wide Receivers Max</small>
                </li>
                <li className='list-group-item'>
                  <small>3 Tight Ends Max</small>
                </li>
                <li className='list-group-item'>
                  <small>2 Kickers Max</small>
                </li>
                <li className='list-group-item' />
              </ul>
            </div>
            <div className='col-12 col-md-8 col-lg-6'>
              <div className='row mt-1'>
                <div className='col-6 pt-1 text-end'>Quaterbacks (QB)</div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='quarterbacks'
                    value={rosterSelect.quarterbacks}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-6 col-lg-6 pt-1 text-end'>
                  Running Backs (RB)
                </div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='runningbacks'
                    value={rosterSelect.runningbacks}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-6 pt-1 text-end'>Wide Receivers (WR)</div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='widereceivers'
                    value={rosterSelect.widereceivers}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-6 pt-1 text-end'>Tight Ends (TE)</div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='tightends'
                    value={rosterSelect.tightends}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-6 pt-1 text-end'>RB / WR</div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='runningbackWidereceiver'
                    value={rosterSelect.runningbackWidereceiver}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-6 pt-1 text-end'>Flex (RB/WR/TE)</div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='flex'
                    value={rosterSelect.flex}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1 mb-3'>
                <div className='col-6 pt-1 text-end'>
                  Super Flex (QB/RB/WR/TE)
                </div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='superFlex'
                    value={rosterSelect.superFlex}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </select>
                </div>
              </div>
              <div className='row mt-1 mb-3'>
                <div className='col-6 pt-1 text-end'>Kickers</div>
                <div className='col-4'>
                  <select
                    className='form-select'
                    id='kickers'
                    value={rosterSelect.kickers}
                    onChange={updateRoster}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 justify-content-center row'>
        <div className='col-xs-12 col-lg-8 border rounded shadow'>
          <div className='row mt-3'>
            <h3 className='col-12 text-center'>Scoring</h3>
          </div>
          <div className='row mb-1'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12 text-center'>
                  <small>
                    Scores must range from -100 to 100 and cannot go more than 2
                    places past the decimal
                  </small>
                </div>
              </div>
              <div className='row justify-content-lg-evenly mt-1 mb-1'>
                {scoringMap.buckets &&
                  scoringMap.buckets.map((bucket) => (
                    <div
                      key={`${bucket}-bucket`}
                      className='col-12 col-lg-5 mt-3 pb-2 border rounded'
                    >
                      <div className='row mb-1'>
                        <div className='col-12 fw-semibold text-center'>
                          {scoringMap.groupScoreBucketMap[bucket]}
                        </div>
                      </div>
                      {Object.keys(scoringMap.groupScoreMap[bucket]).map(
                        (scoreKey) => (
                          <div key={`${scoreKey}-detail`} className='row'>
                            <div className='col-6 pt-1 text-end'>
                              {scoringMap.groupScoreMap[bucket][scoreKey]}
                            </div>
                            <div className='col-6'>
                              <input
                                className='form-control'
                                type='text'
                                name={`${bucket}-${scoreKey}`}
                                value={enteredScore[bucket][scoreKey]}
                                onChange={updateGroupsScore}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5 mb-5'>
        <div className='col-12 text-center'>
          <button className='btn btn-primary' onClick={handleSubmit}>
            Create Group
          </button>
        </div>
      </div>
      <Modal
        onRequestClose={requestCloseModal}
        isOpen={modalOpen}
        contentLabel='modalWindow'
        className='modalWindow'
        overlayClassName='modalOverlay'
        ariaHideApp={false}
      >
        <ImageEditor
          tempAvatar={avatarToUpload}
          saveCroppedAvatar={saveCroppedAvatar}
          openCloseModal={updateModalOpen}
          userCancel={userCancelCrop}
        />
      </Modal>
    </div>
  );
};

export default Session(CreateGroup);
