export const differencesInObj = (original, compare) => {
  const diff = {};
  for (const key in original) {
    if (original[key] !== compare[key]) {
      diff[key] = compare[key];
    }
  }
  return diff;
};

export var countDecimals = (value) => {
  if (!value.toString().includes('.')) {
    return 0;
  }
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0;
  }
  return 0;
};
