import { useEffect, useState, useRef, useContext } from 'react';
import Session from '../../../contexts/Firebase/Session';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import 'rc-slider/assets/index.css';
import axios from 'axios';

import {
  axiosHandler,
  axiosHandlerNoValidation,
  httpErrorHandler,
} from '../../../utils/axiosHandler';
import { CurrentUserContext } from '../../../App';
import NonAdminView from './NonAdminView';
import EditGroup from './Edit';

const GroupProfile = () => {
  const [avatar, updateAvatar] = useState('');
  const [groupInfo, updateGroupInfo] = useState({});
  const [groupPositions, updateGroupPositions] = useState([]);
  const [scoringDetails, updateScoringDetails] = useState({});
  const [leaderboard, updateLeaderboard] = useState([]);
  const [userIsAdmin, updateUserIsGroupAdmin] = useState(false);
  const [positionInfo, updatePositionInfo] = useState({});

  const { currentUser } = useContext(CurrentUserContext);

  const params = useParams();

  const axiosCancel = axios.CancelToken.source();

  useEffect(() => {
    if (params.name && currentUser.username) {
      pullGroupInfo(params.name);
      pullPositionInfo();
    }
  }, [params.name, currentUser.username]);

  useEffect(() => {
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel('Unmounted');
      }
    };
  }, []);

  const pullGroupInfo = async (groupName) => {
    try {
      const { data } = await axiosHandler.get(
        `/api/group/profile/${groupName}`,
        axiosCancel.token
      );
      updateGroupInfo(data.group);
      updateGroupPositions(data.positions);
      updateAvatar(data.avatar);
      updateLeaderboard(data.leaderboard);
      updateScoringDetails({
        scoringPoints: data.scoring,
        scoringBucketDescription: data.scoringBucketDescription,
        scoringDetailDescription: data.scoringDetailDescription,
      });
      updateUserIsGroupAdmin(data.adminStatus);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const pullPositionInfo = async () => {
    try {
      const { data } = await axiosHandlerNoValidation.get(
        '/api/roster/positions',
        axiosCancel.token
      );
      updatePositionInfo(data);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  return (
    <>
      <div className='container'>
        {false ? (
          <EditGroup />
        ) : (
          <NonAdminView
            groupInfo={groupInfo}
            avatar={avatar}
            leaderboard={leaderboard}
            scoringDetails={scoringDetails}
            groupPositions={groupPositions}
            positionInfo={positionInfo}
          />
        )}
      </div>
    </>
  );
};

export default Session(GroupProfile);
