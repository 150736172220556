import { useContext, useEffect, useState } from 'react';
import { axiosHandler, httpErrorHandler } from '../../utils/axiosHandler';
import { CurrentUserContext } from '../../contexts/CurrentUser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Routes from '../../constants/routes';
import EspnPlayerRow from './EspnPlayerRow';

const EspnIdFiller = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const [playersNoEspnId, updatePlayersNoEspnId] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (currentUser.email) {
      if (!currentUser.isAdmin) {
        history.push(Routes.home);
      }
      getPlayers();
    }
  }, [currentUser]);

  const getPlayers = async () => {
    try {
      const { data } = await axiosHandler.get('/api/nfldata/playersNoEspnId');
      updatePlayersNoEspnId(data.playersNullEspnId);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  return (
    <div className='container'>
      <div className='row justify-content-center mt-3'>
        <div className='col-6'>
          {playersNoEspnId.length > 0 &&
            playersNoEspnId.map((player) => (
              <EspnPlayerRow key={player.mySportsId} player={player} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default EspnIdFiller;
