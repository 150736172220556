import { useState, useEffect, useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../../constants/routes.js';
import { CurrentUserContext } from '../../../contexts/CurrentUser/index.js';
import { NFLScheduleContext } from '../../../contexts/NFLSchedule/index.js';
import { Link } from 'react-router-dom';
import { axiosHandler, httpErrorHandler } from '../../../utils/axiosHandler.js';
import axios from 'axios';
import GenericGroupAvatar from '../../../constants/logoImages/avatar/groupPlaceholder.png';

const GroupRow = ({ group }) => {
  const [topScore, setTopScore] = useState('');
  const [scoreTooltip, setScoreTooltip] = useState('');
  const [isInGroup, setIsInGroup] = useState(false);
  const [firstAdmin, setFirstAdmin] = useState({});
  const [groupAvatar, setGroupAvatar] = useState('');

  const { currentUser, pullUserData } = useContext(CurrentUserContext);
  const { currentNFLTime } = useContext(NFLScheduleContext);

  const axiosCancel = axios.CancelToken.source();
  const history = useHistory();

  useEffect(() => {
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel(`Unmounted`);
      }
    };
  }, []);

  useEffect(() => {
    getTopScore(group.groupId, currentNFLTime.season);
    buildScoreTooltip(
      group.groupId,
      currentNFLTime.season,
      currentNFLTime.week
    );
    getGroupAvatar(group);
    setFirstAdmin(group.userlist.find((user) => user.admin === true));
    if (group.userlist.length >= 1 && currentUser.userId) {
      checkInGroup(currentUser.userId);
    }
  }, [
    group.groupId,
    group.userlist,
    currentNFLTime.season,
    currentUser.userId,
  ]);

  const getTopScore = async (groupId, season) => {
    try {
      const { data } = await axiosHandler.get(
        `/api/group/topScore/${groupId}/${season}`,
        axiosCancel.token
      );
      setTopScore(`${data.username} - ${data.totalScore}`);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const buildScoreTooltip = async (groupId, season, week) => {
    try {
      const { data } = await axiosHandler.get(
        `/api/group/leaderboard/${season}/${week}/${groupId}`
      );
      let buildScoreTooltip = '';
      for (const user of data.leaderboard) {
        buildScoreTooltip += `${user.username} - ${user.totalScore}<br/>`;
      }
      setScoreTooltip(buildScoreTooltip);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const checkInGroup = (uId) => {
    const userInGroup = group.userlist.find((user) => user._id === uId);
    setIsInGroup(!userInGroup);
  };

  const getGroupAvatar = async (group) => {
    try {
      if (group.avatar) {
        const { data } = await axiosHandler.get(
          `/api/avatar/groupAvatar/${group.groupId}`
        );
        setGroupAvatar(data);
      } else {
        setGroupAvatar(GenericGroupAvatar);
      }
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const joinGroup = async (groupId) => {
    try {
      await axiosHandler.put('/api/group/join/', {
        userId: currentUser.userId,
        groupId: groupId,
      });
      pullUserData(currentUser.email).then(() => {
        history.push(Routes.home);
      });
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  return (
    <div className='row border rounded mt-3'>
      <Tooltip id='scoreTooltip' />
      <div className='row'>
        <div className='col-1' />
        <div className='d-none d-md-block col-4'>
          <img
            className='rounded mw-100 mt-lg-1 mb-lg-1 groupAvatar'
            name='avatar'
            src={groupAvatar}
          />
        </div>
        <div className='col-12 col-md-7'>
          <div className='row mt-1 text-end'>
            <div className='col-8 col-md-6'>
              <Link
                to={`/profile/group/${group.name}`}
                className='link-dark fs-4 fw-bold me-3'
              >
                {group.name}
              </Link>
            </div>
            <div className='col-4 text-start mt-1'>
              {isInGroup && (
                <button
                  className='btn btn-primary btn-sm mb-2'
                  onClick={() => joinGroup(group.groupId)}
                >
                  Join
                </button>
              )}
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-12'>
              <div className='row'>
                <h6 className='col-12 text-center text-md-start'>
                  {group.description}
                </h6>
              </div>
              <div className='row justfiy-content-md-start'>
                <div className='col-6 col-sm-3 text-end'>
                  <strong>Admin:</strong>
                </div>
                <div className='col-6 col-sm-9'>
                  {firstAdmin && firstAdmin.username}
                </div>
              </div>
              <div
                className='row justify-content-md-start'
                data-tooltip-id='scoreTooltip'
                data-tooltip-html={scoreTooltip}
                role='button'
              >
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-6 col-sm-3 text-end'>
                      <strong>Users:</strong>
                    </div>
                    <div className='col-6 col-sm-9'>
                      {group.userlist.length}
                    </div>
                  </div>
                  <div className='row justify-content-md-start'>
                    <div className='col-6 col-sm-3 text-end'>
                      <strong>Top Score:</strong>
                    </div>
                    <div className='col-6 col-sm-9'>{topScore}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupRow;
